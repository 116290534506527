import React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import Swiper from "react-id-swiper";
import styled from "styled-components";
import media from "styled-media-query";
import 'swiper/swiper.scss'
import SwiperCore, { Autoplay } from 'swiper';

const ImageSlider = ({slides}) => {
  SwiperCore.use([Autoplay]);
  const params = {
    loop: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false
    }
  }
  return (
    <ImageSizeDiv>
      <Swiper {...params}>
          {slides.map(image =>
          <div>
            <PreviewCompatibleImage imageInfo={image} imageStyle={{
              objectFit: "cover",
              objectPosition: "0% 50%",
              maxHeight: "550px",
            }}></PreviewCompatibleImage>
          </div>
          )}
      </Swiper>
    </ImageSizeDiv>
  )
};

export default ImageSlider;

ImageSlider.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    })
  ),
};

const ImageSizeDiv = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 30vh;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 550px;
  `}
`;

