import React from "react";
import PropTypes from "prop-types";
import { Link, navigate, graphql } from "gatsby";
import styled from "styled-components";
import media from "styled-media-query";
import { Parallax, Background } from 'react-parallax';

import Layout from "../components/Layout";
import ImageSlider from "../components/ImageSlider";
import NewsRoll from "../components/NewsRoll";

import BgImage1 from "../img/background-1.png";
import BgImage2 from "../img/news-bg.png"

import ToriiImage1 from "../img/torii-1.jpg";
import ToriiImage2 from "../img/torii-2.jpg";

import KadoImage1 from "../img/kado-1.jpg";
import KadoImage2 from "../img/kado-2.jpg";

import OwnImage1 from "../img/own-1.jpg";

export const IndexPageTemplate = ({ slides }) => (
  <div>
      {/*
      ヘッダーNav
      ヘッダーMenu
      固定の宿泊予約
      固定のご飯予約

      ヒーローイメージのスライダー
      文章
      2カラム：とりいくぐる＆ラウンジ・カド
      ヒーローイメージ＆文章（パララックスだとなおよい）
      最新のお知らせ・イベント

      フッター
      */}
      <ImageSlider slides={slides}></ImageSlider>
      {/*ヒーローイメージのスライダー*/}
      <ParagraphDiv>
        {/*文章*/}
        <Midashi>ふれる、ひろがる、めぐるであい。</Midashi>
        <br />
        <br />
        <Honbun>NAWATE PROJECTは岡山市・奉還町4丁目で<br />
          ゲストハウスやラウンジを運営しています。<br />
          旅と日常がまじわる場所には、これまで出会うことのなかった人々が集います。<br />
          小さくも多様な活動や想いを持ち寄りながら、<br />
          ともにこれからの暮らしをみつけていきませんか。<br />
        </Honbun>
      </ParagraphDiv>
      <Nest2ColumnDiv>
        <ToriiDiv>
          <CenteriseDiv>
            <Image2ColumnBig src={ToriiImage1}></Image2ColumnBig>
              <Komidashi>Guesthouse&Lounge</Komidashi>
              <Midashi>とりいくぐる</Midashi>
              <br />
              <Honbun>泊まる、食べる、散歩する。<br />
              とりいをくぐって入る、家のようなゲストハウス。</Honbun>
          </CenteriseDiv>
          <br />
          <Detail2ColumnDiv>
            <Image2ColumnSmall src={ToriiImage2}></Image2ColumnSmall>
            <SyosaiDiv>
              <Nest2ColumnDiv>
                <SyosaiHeader>宿泊　　　　</SyosaiHeader>
                <SyosaiValue>個室 ¥4,500、ドミトリー ¥3,500<br />
                          check-in 16:00-21:00</SyosaiValue>
              </Nest2ColumnDiv>
              <Nest2ColumnDiv>
                <SyosaiHeader>日中滞在　　</SyosaiHeader>
                <SyosaiValue>1h/¥1,000～<br />
                          11:00-15:00</SyosaiValue>
              </Nest2ColumnDiv>
            </SyosaiDiv>
          </Detail2ColumnDiv>
          <CenteriseDiv>
            <DetailButton onClick={()=>{navigate("/facility/toriikuguru")}}>もっとくわしく</DetailButton>
          </CenteriseDiv>
        </ToriiDiv>
        <KadoDiv>
          <CenteriseDiv>
            <Image2ColumnBig src={KadoImage1}></Image2ColumnBig>
              <Komidashi>奉還町4丁目</Komidashi>
              <Midashi>ラウンジ・カド</Midashi>
              <br />
              <Honbun>食べたり、飲んだり、話したり。<br />
                    街を眺めるラウンジ</Honbun>
          </CenteriseDiv>
          <br />
          <Detail2ColumnDiv>
            <Image2ColumnSmall src={KadoImage2}></Image2ColumnSmall>
            <SyosaiDiv>
              <Nest2ColumnDiv>
                <SyosaiHeader>カドびらき　</SyosaiHeader>
                <SyosaiValue>週末にオープンするラウンジ。美味しいお酒、おつまみ、ごはん、音楽、本のある空間。<br />
                          <br />
                          金  17:00-21:00<br />
                          土・日  13:00-21:00
                          </SyosaiValue>
              </Nest2ColumnDiv>
            </SyosaiDiv>
          </Detail2ColumnDiv>
          <CenteriseDiv>
            <DetailButton onClick={()=>{navigate("/facility/roungekado")}}>もっとくわしく</DetailButton>
          </CenteriseDiv>
        </KadoDiv>
      </Nest2ColumnDiv>
      <OwnDivBg>
        <OwnDiv>
        <MidashiWhite>私たちのこと<br /></MidashiWhite>
        <MidashiUnderbar></MidashiUnderbar>
        <br />
        <HonbunWhite>畷＝NAWATEは、田んぼのあぜ道のこと。<br />
              私たちはPROJECTを通じて、<br />
              田んぼのあぜ道を耕すように、人と人、人と場所の接点をつくります。<br />
              そして、人々、場所の持つ可能性や魅力を<br />
              引き出していくことのできるようなまちをデザインしていきます。</HonbunWhite>
        <DetailButtonWhite onClick={()=>{navigate("/aboutus")}}>もっとくわしく</DetailButtonWhite>
        <br />
        <br />
        <br />
        <br />
        </OwnDiv>
      </OwnDivBg>
      <NewsBg>
        <br />
        <br />
        <CenteriseDiv>
          <Midashi>おしらせ・イベント</Midashi>
          <br />
        <br />
        <NewsRollDiv>
          <NewsRoll mini={true} />
        </NewsRollDiv>
          <DetailButton onClick={()=>{navigate("/news")}}>もっと見る</DetailButton>
        </CenteriseDiv>
      </NewsBg>
  </div>
);

const OwnDivBg = ({children}) => (
  <Parallax strength={-250}>
    <ParallaxBackground>
      <ImageBig src={OwnImage1} alt="aboutus"></ImageBig>
    </ParallaxBackground>
    {children}
  </Parallax>
);

const ImageBig = styled.img`
  object-fit: cover;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 100vw;
    height: 90vh;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    width: 100vw;
    height: 150vh;
  `}
`;

const ParallaxBackground = styled(Background)`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 100vw;
    height: 90vh;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    width: 100vw;
    height: 150vh;
  `}
`;

const ParagraphDiv = styled.div`
  background-image: url(${BgImage1});
  background-repeat: no-repeat;

  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 40vh;
    background-size: 100vw 20vh;
    background-position: 0 0;
    margin: 10vh 0 5vh 0;
    padding: 10vh 3vw 0 3vw;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 80%;
    background-size: 80vw 80vh;
    background-position: 20vw 0;
    margin: 0 0 10vh 0;
    padding: 20vh 0 3vh 5vw;
    line-height: 3em;
  `}
  
`;

const ToriiDiv = styled.div`
  padding: 0 2vw 0 5vw;
  flex: 1;
`;

const KadoDiv = styled.div`
  padding: 0 5vw 0 2vw;
  flex: 1;
`;

const OwnDiv = styled.div`
  padding: 5vh 0 3vh 5vw;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 20em;
    text-align: center;
    line-height: 1.2em;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 40em;
    line-height: 3em;
  `}
`;

const NewsBg = styled.div`
  width: 100%;
  background-image: url(${BgImage2});
  background-repeat: no-repeat;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    background-position: center 0;
    background-size: 100%;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    padding: 0 15vw 0 15vw;
    background-position: center center;
    background-size: 90%;
  `}
`;

const NewsRollDiv = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 90vw;
    margin: 0 auto 0 auto;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    width: 90%;
    margin: 0 auto 0 auto;
  `}
`;

const CenteriseDiv = styled.div`
  text-align: center;
`;

const Nest2ColumnDiv = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    display: flex;
    margin: 0 0 0 0;
  `}
`;

const Detail2ColumnDiv = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    display: flex;
    margin: 0 0 0 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    display: flex;
    margin: 0 0 0 0;
  `}
`;

const Image2ColumnBig = styled.img`
  width: 100%;
  border-radius: 10px;
  margin: 0 0 3vh 0;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 25vh;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 50vh;
  `}
`;

const Image2ColumnSmall = styled.img`
  object-fit: cover;
  object-position: 0 100%;
  border-radius: 5px;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 35vw;
    height: 12vh;
    margin: 2em 3vw 0 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    width: 15vw;
    height: 20vh;
    margin: 1em 3vw 0 0;
  `}
`;

const DetailButton = styled.button`
  background: #666666;
  margin: 5vh 0 10vh 0;
  border: none;
  border-radius: 10px;
  outline: none;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  color: #ffffff;

  &:hover {
    background: #f6f5f1;
    border: 1px #666666 solid;
    border-radius: 10px;
    color: #666666;
    pointer: cursor;
  }
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 50vw;
    height: 5vh;
    font-size: 10pt;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    width: 20vw;
    height: 10vh;
    font-size: 14pt;
  `}
`;

const DetailButtonWhite = styled.button`
  background: #ffffff;
  margin: 5vh 0 10vh 0;
  border: none;
  border-radius: 10px;
  outline: none;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  color: #666666;

  &:hover {
    background: none;
    border: 1px #ffffff solid;
    border-radius: 10px;
    color: #ffffff;
    pointer: cursor;
  }
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 50vw;
    height: 5vh;
    font-size: 10pt;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    width: 20vw;
    height: 10vh;
    font-size: 14pt;
  `}
`;

const Komidashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 14pt;
`;

const Midashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 12pt;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    font-size: 20pt;
  `}
`;

const MidashiWhite = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  color: #ffffff;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 12pt;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    font-size: 20pt;
  `}
`;

const MidashiUnderbar = styled.div`
  background: #ffffff;
  border-radius: 2px;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 6em;
    height: 1px;
    margin: auto;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    width: 12em;
    height: 3px;
  `}
`;

const Honbun = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 10pt;
    word-wrap: break-word;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    font-size: 14pt;
  `}
`

const HonbunWhite = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  color: #ffffff;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    margin: 0 10vw 0 10vw;
    font-size: 10pt;
    word-wrap: break-word;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    font-size: 14pt;
  `}
`

const SyosaiDiv = styled.div`
  width: 70%;
  margin: 3vh 0 0 0;
  font-family: 'Noto Sans JP', sans-serif;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 8pt;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    font-size: 10pt;
  `}
`;

const SyosaiHeader = styled.span`
  font-weight: 700;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    background-color: #E7D0A9;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
  `}
`
const SyosaiValue = styled.p`
  font-weight: 400;
  flex: 1;
`

IndexPageTemplate.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }))
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;  

  return (
    <Layout>
      <IndexPageTemplate 
        slides={frontmatter.slides}
        />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        slides {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
